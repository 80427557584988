.row-center { display: flex; flex-direction: row; align-items: center;}
.col-center {display: flex;flex-direction: column; align-items: center;}
.col {display: flex;flex-direction: column;}
.row { display: flex; flex-direction: row;}
.wrap { display: flex; flex-wrap:wrap;}


.overflow-hidden { overflow: hidden;}
.overflow-auto { overflow: auto;}
.overflow-x-auto { overflow-x:  auto;}
.overflow-x-hidden { overflow-x:  hidden;}
.overflow-y-auto { overflow-y:  auto;}
.overflow-y-hidden { overflow-y:  hidden;}
.hidden{ display: none;}
.flex-grow{flex-grow: 1;}
.flex-1{flex: 1;}

.shdow-0{box-shadow: 0px 0px 10px rgba(0,0,0,0.20); }
.shdow-1{box-shadow: 0px 0px 10px rgba(0,0,0,0.30); }
.shdow-2{box-shadow: 0px 0px 10px rgba(0,0,0,0.40); }
.shdow-3{box-shadow: 0px 0px 10px rgba(0,0,0,0.50); }
.shdow-4{box-shadow: 0px 0px 10px rgba(0,0,0,0.60); }

.rounded-1 {border-radius: 5px;}
.rounded-2 {border-radius: 10px;}
.rounded-3 {border-radius: 15px;}
.rounded-4 {border-radius: 20px;}
.rounded-5 {border-radius: 25px;}
.rounded-full{border-radius: 50%;}

.fixed {position:fixed;}
.sticky {position:sticky;}
.absolute {position:absolute;}
.relative {position: relative;}

.justify-around{justify-content: space-around;}
.justify-between{justify-content: space-between;}
.justify-center{justify-content: center;}

.items-center{align-items: center;}
.items-start{align-items: flex-start;}
.items-end{align-items: flex-end;}

.text-center{text-align: center;}
.text-left{text-align:left ;}
.text-start{text-align:start ;}

.p-1{padding: 4px;}
.p-2{padding: 6px;}
.p-3{padding: 8px;}
.p-4{padding: 10px;}
.p-5{padding: 12px;}
.p-6{padding: 14px;}
.p-7{padding: 16px;}
.p-8{padding: 18px;}
.p-9{padding: 24px;}
.p-10{padding: 22px;}

.py-1{padding-block: 4px;}
.py-2{padding-block: 6px;}
.py-3{padding-block: 8px;}
.py-4{padding-block: 10px;}
.py-5{padding-block: 12px;}
.py-6{padding-block: 14px;}
.py-7{padding-block: 16px;}
.py-8{padding-block: 18px;}
.py-9{padding-block: 24px;}
.py-10{padding-block: 28px;}

.px-1{padding-inline: 4px;}
.px-2{padding-inline: 6px;}
.px-3{padding-inline: 8px;}
.px-4{padding-inline: 10px;}
.px-5{padding-inline: 12px;}
.px-6{padding-inline: 14px;}
.px-7{padding-inline: 16px;}
.px-8{padding-inline: 18px;}
.px-9{padding-inline: 24px;}
.px-10{padding-inline: 28px;}

.m-1{margin: 4px;}
.m-2{margin: 6px;}
.m-3{margin: 8px;}
.m-4{margin: 10px;}
.m-5{margin: 12px;}
.m-6{margin: 14px;}
.m-7{margin: 16px;}
.m-8{margin: 18px;}
.m-9{margin: 24px;}
.m-10{margin: 28px;}
.m-auto{margin:auto;}

.my-1{margin-block: 4px ;}
.my-2{margin-block: 6px ;}
.my-3{margin-block: 8px ;}
.my-4{margin-block: 10px ;}
.my-5{margin-block: 12px ;}
.my-6{margin-block: 14px ;}
.my-7{margin-block: 16px ;}
.my-8{margin-block: 18px ;}
.my-9{margin-block: 24px ;}
.my-10{margin-block: 28px ;}
.my-auto{margin-block:auto;}

.mx-1{margin-inline:4px;}
.mx-2{margin-inline:6px;}
.mx-3{margin-inline:8px;}
.mx-4{margin-inline:10px;}
.mx-5{margin-inline:12px;}
.mx-6{margin-inline:14px;}
.mx-7{margin-inline:16px;}
.mx-8{margin-inline:18px;}
.mx-9{margin-inline:24px;}
.mx-10{margin-inline:28px;}
.mx-auto{margin-inline:auto;}
 
.gap-1{gap: 4px;}
.gap-2{gap: 6px;}
.gap-3{gap: 8px;}
.gap-4{gap: 10px;}
.gap-5{gap: 12px;}
.gap-6{gap: 14px;}
.gap-7{gap: 16px;}
.gap-8{gap: 18px;}
.gap-9{gap: 24px;}
.gap-10{gap: 28px;}

.text-xs{font-size: 8px;}
.text-sm{font-size: 10px;}
.text-md{font-size: 12px;}
.text-lg{font-size: 14px;}
.text-xl{font-size: 16px;}
.text-2xl{font-size: 18px;}
.text-3xl{font-size: 20px;}
.text-4xl{font-size: 24px;}
.text-5xl{font-size: 28px;}
.text-6xl{font-size: 34px;}

.z-1{z-index: 1;}
.z-2{z-index: 2;}
.z-3{z-index: 3;}
.z-4{z-index: 4;}
.z-5{z-index: 5;}
.z-6{z-index: 6;}
.z-7{z-index: 7;}
.z-8{z-index: 8;}
.z-9{z-index: 9;}
.z-10{z-index: 10;}

.backdrop-blur-none{backdrop-filter: blur(0);}
.backdrop-blur-sm{backdrop-filter: blur(4px);}
.backdrop-blur-md{backdrop-filter: blur(8px);}
.backdrop-blur-lg{backdrop-filter: blur(16px);}
.backdrop-blur-xl{backdrop-filter: blur(24px);}
.backdrop-blur-2xl{backdrop-filter: blur(40px);}
.backdrop-blur-3xl{backdrop-filter: blur(64px);}

.inset-0{inset: 0;}
.inset-1{inset: 4px;}
.inset-2{inset: 6px;}
.inset-3{inset: 8px;}
.inset-4{inset: 10px;}
.inset-5{inset: 12px;}
.inset-6{inset: 14px;}
.inset-7{inset: 16px;}
.inset-8{inset: 18px;}
.inset-9{inset: 24px;}
.inset-10{inset: 28px;}

.max-w-xs{
    max-width: 150px;
}
.max-w-sm{
    max-width: 250px;
}
.max-w-md{
    max-width: 420px;
}
.max-w-lg{
    max-width: 768px;
}
.max-w-xl{
    max-width: 1024px;
}
.max-w-2xl{
    max-width: 1280px;
}
.max-w-3xl{
    max-width: 1536px;
}
.max-w-4xl{
    max-width: 1920px;
}
.h-full{
    height:90vh
}


input {border: none; outline-width: 0px;  }
.pointer {cursor: pointer; }

.top-0 {top: 0; }
.left-0 {left: 0; }
.right-0 {right: 0; }
.bottom-0 {bottom: 0; }

.top-1 {top: 45px; }
.left-1 {left: 45px; }
.right-1 {right: 45px; }
.bottom-1 {bottom: 45px; }

.top-2 {top: 90px; }
.left-2 {left: 90px; }
.right-2 {right: 90px; }
.bottom-2 {bottom: 90px; }

.col-span-full{grid-column: 1 / -1;} 
