.categories-card {
  padding: 10px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.30);
}


.categories-card img  {
  object-fit: contain;
  width: 150px;
  max-height: 100px;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}
 
.categories-card p {
    font-family: "'GE Dinkum Medium'";
}